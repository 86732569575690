<template>
  <b-modal 
    body-class="select-campaign-modal"  
    @hidden="CloseModalNetwork"
    centered 
    hide-footer 
    hide-header 
    v-model="show"
    :size="size">
    <div :class="`header-select-campaign ${padding}`" >
      <feather-icon
          icon="XIcon"
          size="24"
          :class="closeIcon"
          @click="show = !show"
      />
      <slot name="title"></slot>
    </div>
    <div :class="bodyClass">
      <slot name="body"></slot>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';

export default {
  name: 'ModalBrandme',
  components: {
    BModal,
  },
  data() {
    return {
      show: this.show_modal,
    }
  },
  methods: {
    CloseModalNetwork() {
      this.$emit('hidden')
    },
  },
  props: {
    show_modal: {
      type: Boolean,
      required: true
    },
    padding: {
      type: String,
      default: 'p-1'
    },
    size: {
      type: String,
      default: 'md'
    },
    closeIcon: {
      type: String,
      default: 'close-select-campaign-modal'
    },
    bodyClass: {
      type: String,
      default: 'p-1'
    }
  },
}
</script>
<style>
.select-campaign-modal {
  padding: 0px !important;
}
.close-select-campaign-modal {
  display: block;
  color: rgb(155, 155, 155);
  margin: 0 1.3em 0 0;
}
.close-select-campaign-modal:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.header-select-campaign {
  padding: 1em;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  align-items: center;
}
.header-select-campaign span {
  font-size: 18px;
}
</style>