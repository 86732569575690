<template>
  <div>
    <b-row>
      <b-col class="height-cover">
        <b-card
          :img-src="cover"
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile height-cover position-relative"
        >
          <div class="btn-edit-cover">
            <b-button variant="primary" :disabled="disabled_update" @click="openFile('cover')">
              {{$t('campaigns.editCover')}}
            </b-button>
          </div>
          <div class="back-campaign-button">
            <b-button variant="primary" @click="$router.push({ name: checkOutHome() ? 'realty-campaign' : 'campaign', params: { id: $route.params.uuid }})">
              {{$t('campaigndetail')}}
            </b-button>
          </div>
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <div class="btn-edit-logo">
                <b-button variant="outline-primary" class="btn-icon rounded-circle" :disabled="disabled_update" @click="openFile('logo')">
                  <feather-icon icon="EditIcon" />
                </b-button>
              </div>
              <b-avatar size="164" variant="light" :src="logo"/>
            </div>
          </div>
          <div class="title-campaign">
            <h1>
              {{ campaignInfo.name }}
              <b-button variant="flat-primary" class="btn-icon rounded-circle" v-b-modal.modal-name :disabled="disabled_update">
                <feather-icon icon="EditIcon" />
              </b-button>
            </h1>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-for="(custom, index) in requirements" :key="index">
      <b-col>
        <b-card>
          <div class="d-flex align-items-center">
            <h3 class="mb-0 blueC">{{ custom.front_name }}</h3>
            <b-button variant="flat-primary" class="btn-icon" @click="openModalEdit(custom)" :disabled="disabled_update">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button variant="flat-danger" class="btn-icon" @click="deleteCustom(custom.uuid)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
          <div class="plusGrand" v-html="custom.form_value"></div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="text-center">
      <b-col>
        <b-button variant="success" :disabled="disabled_update" @click="showModalAddCustom()"> {{$t('campaigns.addRequirement')}} de red</b-button>
      </b-col>
    </b-row>

    <!--  MODALS SECTION   -->
    <b-form-file
      id="cover"
      ref="file_upload"
      accept=".jpeg, .jpg, .png"
      class="d-none"
      @change="hasChangedFile"
    />

    <b-modal
      id="modal-name"
      cancel-variant="outline-secondary"
      ok-title="OK"
      :cancel-title="$t('campaigns.close')"
      centered
      :title="$t('campaigns.changesCampaign')"
      @ok="setSelectedTitle(name)"
    >
      <label>{{$t('campaigns.changeCampaign')}}</label>
      <b-input-group>
        <b-form-input v-model="name" ref="name" :placeholder="campaignInfo.name" autocomplete="off"/>
      </b-input-group>
    </b-modal>

    <modal-brandme size="xl" :show_modal="show_modal" :key="change_modal">
      <template #title>{{$t('campaigns.newRequirement')}}</template>
      <template #body>
        <new-requirement
          :editable_data="editable_custom"
          :campaign_uuid="$route.params.uuid"
          :order="custom_fields.length"
          @closeModal="closeModalCustomField"
          @customCreated="customCreated"
          type_custom_field="network"
        ></new-requirement>
      </template>
    </modal-brandme>

  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BAvatar, BButton, BFormFile, BFormInput, BInputGroup
} from 'bootstrap-vue'
import newRequirement from './newRequirement.vue'
import ModalBrandme from '../components/modal/ModalBrandme.vue'
import service from "@/services/campaign";
import utils from '@/libs/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BFormFile,
    BFormInput,
    BInputGroup,
    newRequirement,
    ModalBrandme
  },
  data () {
    return {
      upload_type: null,
      disabled_update: false,
      name: '',
      urlHost: window.location.host,
      campaignInfo: {},
      logo: '',
      cover: '',
      custom_fields: [],
      show_modal: false,
      change_modal: false,
      modal_edit: false,
      editable_custom: null,
      requirements: []
    }
  },
  async created () {
    this.$vs.loading();
    this.callAxios()

    this.disabled_update = this.disabledUpdateCampaign();
  },
  methods:{
    openModalEdit(custom) {
      this.editable_custom = custom
      this.show_modal = true
      this.change_modal = !this.change_modal
    },
    closeModalCustomField() {
      this.show_modal = false
      this.change_modal = !this.change_modal
      this.editable_custom = null
    },
    showModalAddCustom() {
      this.editable_custom = null;
      this.show_modal = true
      this.change_modal = !this.change_modal
    },
    checkOutHome() {
      return this.urlHost.includes('outofhome')
    },
    openFile(upload_type) {
      this.upload_type = upload_type;
      this.$refs.file_upload.$refs['input'].click();
    },
    hasChangedFile() {
      setTimeout(() => {
        if (this.upload_type === 'logo') this.uploadLogo();
        else this.uploadCover();
      },200)
    },
    orderCustomFields(custom_fields) {
      custom_fields.sort(function(a, b) {
        return a.order - b.order;
      });

      return custom_fields
    },
    deleteCustom (custom_uuid) {
      this.$swal({
        title: this.$t('campaigns.textConfirmRequest'),
        text: this.$t('campaigns.textRemoveRequest'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn confirm-button ',
          cancelButton: 'btn btn-outline-danger mr-1'
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirmRequest'),
        reverseButtons: true,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          service.deleteCustomField(this.$route.params.uuid, custom_uuid)
            .then(response => {
              if (response.status) {
                this.$swal({
                  title:  this.$t('campaigns.Error'),
                  text: this.$t('campaigns.requirementError'),
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false
                })
              } else {
                this.custom_fields = this.custom_fields.filter(function(custom) {
                  return custom.uuid !== custom_uuid
                })
              }
            })
            .catch(() => {
              this.$swal({
                title: this.$t('campaigns.Error'),
                text: this.$t('campaigns.requirementError'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false
              })
            })
        }
      })
    },
    callAxios () {
      service.getCampaign(this.$route.params.uuid)
        .then(response => {
          const requirements = response.custom_fields.filter(item => item.network !== '' && item.network !== null && item.type_field !== '' && item.type_field !== null)
          this.name = response.name
          this.campaignInfo = response ? response : {}
          this.logo = response.logo ? response.logo : ''
          this.cover = response.cover ? response.cover : ''
          this.custom_fields = this.orderCustomFields(response.custom_fields)
          this.requirements = this.orderCustomFields(requirements)
          setTimeout(() => {
            this.$vs.loading.close();
          }, 500)
        })
        .catch(() => {
        })
    },
    uploadCover () {
      if (this.disabledUpdateCampaign()) return;
      const cover = this.$refs.file_upload.files[0]

      const formData = new FormData()
      formData.append('cover', cover)
      this.update(this.$route.params.uuid, formData)
    },
    uploadLogo () {
      if (this.disabledUpdateCampaign()) return;
      const logo = this.$refs.file_upload.files[0]

      const formData = new FormData()
      formData.append('logo', logo)

      this.update(this.$route.params.uuid, formData)
    },
    setSelectedTitle (name) {
      if (this.disabledUpdateCampaign()) return;
      const formData = new FormData()
      formData.append('name', name)
      this.update(this.$route.params.uuid, formData)
    },
    customCreated() {
      this.closeModalCustomField()
      this.callAxios()
    },
    disabledUpdateCampaign() {
      return !utils.hasPermission('change_campaign')
    },
    update (campaign_uuid, formdata) {
      service.updateCampaign(campaign_uuid, formdata)
        .then(response => {
          if (response.uuid) {
            this.$swal({
              title: this.$t('campaigns.changeSuccess'),
              text: this.$t('campaigns.changeSuccess'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false,
              heightAuto: false
            })
            this.callAxios()
          } else {
            const messages = Object.entries(response.data.response.message)
            let message = ''

            messages.forEach(error => {
              error[1].forEach(m => {
                message += m
              })
            })
            this.$swal({
              title:  this.$t('campaigns.Error'),
              text: message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false,
              heightAuto: false
            })
            this.callAxios()
          }
        })
        .catch(() => {
        })
    }
  },
  watch: {
  }
}
</script>

<style>
.height-cover .card-img-top{
  max-height: 200px !important;
  object-fit: cover;
}
.height-cover .card-profile .card-body .profile-image-wrapper .profile-image img{
  height: 150px !important;
  width: 150px !important;
}
.height-cover .card-profile .card-body{
  padding: 9rem 2.3rem 1.5rem 2.3rem;
}

.changeTitle{
  position:absolute;
  top: -5px;
  right: -50px;
  cursor: pointer;
}
.plusGrand{
  font-size: 16px;
}
.back-campaign-button {
  position: absolute; 
  top: -50px; 
  left: 10px;
}
.btn-edit-cover {
  position: absolute;
  top: -50px;
  right: 10px;
}
.btn-edit-logo {
  position: absolute;
  bottom: 0px;
  right: 0;
  z-index: 9;
  background: white;
  border-radius: 50%;
  padding: 5px;
}
.title-campaign {
  position: relative;
  display: inline-block;
}
</style>
